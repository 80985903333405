import { memo } from 'react';
import Link from 'next/link';

import * as S from './Button.sc';

import { event, Event } from '@utils/gtag';

type ButtonProps = {
  analytics?: Event;
  icon?: any;
  layout?: string;
  onClick?: (e: any) => Promise<any> | void;
  smallFont?: boolean;
  text: string;
  type?: 'confirm' | 'secondary' | 'submit' | 'remove';
  url?: string;
};

const Button = ({
  analytics,
  icon,
  layout = 'icon-beside',
  onClick,
  smallFont = false,
  text,
  type = 'confirm',
  url,
}: ButtonProps) => {
  const handleClick = (e) => {
    if (analytics) {
      event(analytics);
    }

    if (onClick) {
      onClick(e);
    }
  };

  const content = (
    <S.Content data-small={smallFont.toString()}>
      {icon ? <S.Icon data-layout={layout}>{icon}</S.Icon> : null}
      {text}

      {type === 'confirm' ? (
        <svg
          className="arrow"
          width="18"
          height="18"
          viewBox="0 0 10 10"
          aria-hidden="true"
        >
          <g fillRule="evenodd">
            <path className="line" d="M0 5h7"></path>
            <path className="tip" d="M1 1l4 4-4 4"></path>
          </g>
        </svg>
      ) : null}
    </S.Content>
  );

  if (url) {
    const isExternal = url.indexOf('http') > -1;
    return isExternal ? (
      <S.Anchor
        href={url}
        onClick={handleClick}
        rel="noreferrer"
        target="_blank"
      >
        <S.Container data-icon={!!icon} data-layout={layout} data-type={type}>
          {content}
        </S.Container>
      </S.Anchor>
    ) : (
      <Link href={url} passHref legacyBehavior>
        <S.Anchor onClick={handleClick}>
          <S.Container data-icon={!!icon} data-layout={layout} data-type={type}>
            {content}
          </S.Container>
        </S.Anchor>
      </Link>
    );
  } else {
    if (type === 'submit') {
      return (
        <S.Button type="submit" onClick={handleClick}>
          <S.Container
            data-icon={!!icon}
            data-layout={layout}
            data-type="confirm"
          >
            {content}
          </S.Container>
        </S.Button>
      );
    }

    return (
      <S.Button onClick={handleClick}>
        <S.Container data-icon={!!icon} data-layout={layout} data-type={type}>
          {content}
        </S.Container>
      </S.Button>
    );
  }
};

export default memo(Button);
