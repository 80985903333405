import styled, { css } from 'styled-components';

const sharedStyle = css`
  border: 0;
  display: inline-block;
  padding: 6px;
  position: relative;
  z-index: 0;
`;

export const Anchor = styled.a`
  ${sharedStyle}
`;

export const Button = styled.button`
  background: none;
  ${sharedStyle}
`;

export const Icon = styled.div`
  height: 40px;
  width: 40px;

  &[data-layout='icon-above'] {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &[data-layout='icon-beside'] {
    left: -50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Container = styled.div`
  background: transparent;
  border: solid 3px var(--theme-color-text);
  border-radius: 20px;
  color: var(--theme-color-always-light);
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  overflow: hidden;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
  }

  &[data-icon='true']&[data-layout='icon-beside'] {
    padding-bottom: 1.5rem;
    padding-left: 5rem;
    padding-top: 1.5rem;
    text-align: left;

    @media ${(props) => props.theme.breakpoints.lg} {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  &[data-type='confirm'] {
    padding-right: 0.5rem;

    &:before {
      background: var(--theme-color-button-confirm);
    }
  }

  &[data-type='secondary'] {
    &:before {
      background: var(--theme-color-button-secondary);
    }
  }

  &[data-type='remove'] {
    &:before {
      background: var(--theme-color-button-remove);
    }
  }

  &:before {
    height: 100%;
    left: 0;
    top: 0;
    transition: all 150ms;
    width: 100%;
  }

  &:after {
    border-radius: 20px;
    height: 100%;
    left: 0;
    top: 0;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 1.6rem;
  }

  .arrow {
    stroke: currentColor;
    fill: none;
    height: 15px;
    margin-left: 10px;
    position: relative;
    stroke-width: 1px;
    top: 1px;
    width: 15px;

    @media ${(props) => props.theme.breakpoints.lg} {
      height: 18px;
      stroke-width: 1.5px;
      width: 18px;
    }
  }

  .tip {
    transition: transform 150ms;
  }

  .line {
    opacity: 0;
    transition: opacity 150ms;
  }

  ${Anchor}:hover & , ${Button}:hover & {
    box-shadow: inset 0 0 0 0, 0 0 0 0;
    color: var(--theme-color-always-light);

    &[data-type='confirm'] {
      &:before {
        background: var(--theme-color-button-confirm-hover);
      }
    }

    &[data-type='secondary'] {
      &:before {
        background: var(--theme-color-button-secondary-hover);
      }
    }

    &[data-type='remove'] {
      &:before {
        background: var(--theme-color-button-remove-hover);
      }
    }

    .tip {
      transform: translateX(3px);
    }

    .line {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  &[data-small='true'] {
    font-size: 0.7em;
  }
`;
